<template>
  <v-card
    tile
    flat
    style="width: 100%">
    <v-card
      v-if="!load_contacts"
      flat
      tile
      style="width: 100%">
      <!-- start project table -->
        <v-data-table 
          hide-default-footer
          disable-pagination
          :items-per-page="20"
          id="projects_table"
          light 
          :headers="headers" 
          :items="contacts" 
          item-key="_id"
        >
          <template 
            v-slot:item="props">
            <tr>
              <td
                class="text-left">
                <p 
                  v-text="props.item._id"
                  class="text_content"
                />
              </td>
              <td
                class="text-left">
                <p 
                  v-text="props.item.name"
                  class="text_content"
                />
              </td>
              <td
                class="text-left">
                <p 
                  v-text="props.item.email"
                  class="text_content"
                />
              </td>
              <td
                class="text-left">
                <p 
                  v-text="props.item.phone"
                  class="text_content"
                />
              </td>
              <td
                class="text-left">
                <p 
                  v-text="props.item.message"
                  class="text_content"
                />
              </td>
              <td
                class="text-left">
                  <v-btn  
                    @click="set_view_contact(props.item)"
                    small
                    icon
                    text>
                    <v-icon
                      color="black">
                      mdi-eye
                    </v-icon>
                  </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-pagination
          v-model="current_page"
          :length="num_pages"
        ></v-pagination>
      <!-- end project table -->
    </v-card>
    <v-card
      v-else
      flat
      style="background-color: #FAFAFA !important"
      >
      <v-container
        style="background-color: #FAFAFA !important">
        <v-layout
          row
          justify-center>
          <loading-animation />
        </v-layout>
      </v-container>
    </v-card>
    <contacts-view
      @close="contact_description = false"
      :bActive="contact_description"
      :form="view_form"
    />
  </v-card>
</template>
<script>
export default {
  data: () => ({
    contact_description: false,
    view_form: null,
    load_contacts: false,
    headers: [
      {
        text: 'ID',
        value: '_id',
        sortable: false
      },
      {
        text: 'Nombre',
        value: 'name',
        sortable: false
      },
      {
        text: 'Correo',
        value: 'email',
        sortable: false
      },
      {
        text: 'Teléfono',
        align: 'phone',
        sortable: false
      },
      {
        text: 'Mensaje',
        align: 'message',
        sortable: false
      },
      {
        text: 'Acciones',
        align: 'actions',
        sortable: false
      }
    ],
    contacts: [],
    num_pages: 0,
    current_page: 1,
  }),
  computed: {
    usr_token () {
      return 'Bearer ' + this.$store.state.token
    }
  },
  watch: {
    current_page: function () {
      this.get_contacts()
    }
  },
  components: {
    ContactsView: () => import('@/components/sections/Admin/Contact/ContactText')
  },
  mounted () {
    this.get_contacts()
  },
  methods: {
    get_contacts () {
      this.load_contacts = true
      db.get(`${uri}/api/v1/contacts`, {
        headers: {
          'Authorization': this.usr_token,
        },
        params: {
          page: this.current_page
        }
      })
        .then((response) => {
          this.num_pages = response.data.numPages
          this.contacts = response.data.contacts
          this.load_contacts = false
          this.contacts = response.data.contacts
          this.$store.commit('toggle_alert', {color: 'success', text: response.data.message})
        })
        .catch((error) => {
          this.load_contacts = false
          this.$store.commit('toggle_alert', {color: 'fail', text: 'Hubo un error con la conexión. Favor de intentarlo más tarde.'})
        })
    },
    set_view_contact (form) {
      this.view_form = form
      this.contact_description = true
    }
  }
}
</script>